import { render, staticRenderFns } from "./CharacterBuilder.vue?vue&type=template&id=a70f29dc&scoped=true"
import script from "./CharacterBuilder.vue?vue&type=script&lang=js"
export * from "./CharacterBuilder.vue?vue&type=script&lang=js"
import style0 from "./CharacterBuilder.vue?vue&type=style&index=0&id=a70f29dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a70f29dc",
  null
  
)

export default component.exports